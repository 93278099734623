import React from "react"
import { Link } from "gatsby"

export default function PostSummaryCollection ({posts}) {
  const postSummaries = posts.map((post) => {
    return (
    <div key={post.node.id} id={`post-${post.node.id}`} className="post type-post format-standard hentry">
          <h2 className="post-title"><Link to={post.node.frontmatter.permalink} dangerouslySetInnerHTML={{ __html: post.node.frontmatter.title }}></Link></h2>
          <div className="postmeta">
              <span className="post-date">{post.node.frontmatter.date}</span>
          </div>
        <div className="entry" dangerouslySetInnerHTML={{ __html: post.node.html }}></div>
    </div>)
  });
  return(
    <div id="content">
      {postSummaries}
    </div>
  )
}
